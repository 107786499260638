import * as React from "react"
import {Container, Wrapper } from "../styles/eventStyles"
import { View, } from "../styles/homeStyles"
import {Blocks,
  Block,
  Details,
  Title,
  Para,
  Subtitle,
  Intro} from "../styles/sponsorStyles.js"
  import { InfoContainer, InfoWrapper, Left, Right, Name, Info} from "../styles/modalStyles"

import Layout from "../components/layout"
import Seo from "../components/seo"

export default class Sponsor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          submitted: false
        }
      }
    
      render() {
        return (
  <Layout>
    <Seo title="Sponsor" />
    <Container>
        <Wrapper style={{width: "100%"}}>
            <View style={{height: "40vh"}}>
                <Title style={{textAlign: "center"}}>BECOME A SPONSOR</Title>
                <Subtitle>Help us improve the arts</Subtitle>
            </View>
            <Intro>Take a look below to see various options for sponsoring the Young Arts youth contest. There are various tiers you can take part in and each have their own benefits.</Intro>
            <Details>
              <Title>Complete <br/>Event: $30K</Title>
              <Para>Sponsor the entire event! Doing this means scholarship money for winners toward college, MacBook laptops for schooling and completing their artwork, art supplies for participants, video production for documenting the event, and more! <br/><br/>Sponsoring this tier means that you will have prime placement in any and all coverage for this event, including print ads, posters, video trailers, mini-doc videos, and more. This includes full screen ad placement at the end of any related videos, and tagging in social media posts regarding the event. This counts for official Inland Arts channels, and partner accounts.</Para>
            </Details>
            <Details>
              <Title style={{textAlign: "right"}}>Tier 2 <br/>$5-10K</Title>
              <Para>These sponsors will receive logo placement on any and all posters, print ads, social media ads, and minor placement at the end of videos covering this event release through official Inland Arts channels.</Para>
            </Details>
            <Details>
              <Title>Tier 3 <br/>$1-5K</Title>
              <Para>These sponsors will receive logo placement on posters, print ads, and social media ads run for this event. They will also be tagged on posts related to this event.</Para>
            </Details>
            <Details style={{textAlign: "right"}}>
              <Title>Tier 4 <br/>$1-1K</Title>
              <Para>We appreciate every dollar given, so be assured that we are thankful for your generosity! However, these donations are anonymous.</Para>
            </Details>
            <View style={{height: "40vh"}}>
                <Title style={{textAlign: "center"}}>Alternative <br/> Sponsorship</Title>
                <Subtitle>Have another idea how you could contribute? Feel free to contact us with the info below. We'd love to chat with you!</Subtitle>
            </View>
            <InfoContainer>
                <InfoWrapper>
                    <Left>
                        <Name>Morgan Caldbeck</Name>
                        <Info space>Director of Media / JLTech LLC</Info>
                        <Info>406.261.0421</Info>
                        <Info>Morgan@JLTech</Info>
                    </Left>
                    <Right>
                        <Name>Dario Re</Name>
                        <Info space>Creative Director</Info>
                        <Info>509.655.2044</Info>
                        <Info>dariore555@gmail.com</Info>
                    </Right>
                </InfoWrapper>
            </InfoContainer>
        </Wrapper>
    </Container>
  </Layout>
        );
    };
};

