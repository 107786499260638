import styled from 'styled-components'
import { Link } from "gatsby"

const Details = styled.div`
    display: flex;
    width: 90%;
    justify-content: center;
    flex-flow: row wrap;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 25px;
    padding-bottom: 25px;

`

const Blocks = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    place-self: flex-start;
    margin-top: -30px;
    width: 80%;
    z-index: 2;
`;

const Block = styled.div`
    font-size: 22px;
    color: #fff;
    background-color: #202020;
    padding: 50px;
    width: 32%;
    margin: 5px;
`;

const Title = styled.div`
    font-size: 68px;
    color: #202020;
    width: 100%;
    z-index: 2;
    align-self: center;
    line-height: 1;
    text-align: left;
    @media (max-width: 768px) {
        font-size: 45px;
        padding-bottom: 10px;
    }
`;

const Subtitle = styled.div`
display: flex;
    font-size: 28px;
    width: 100%;
    font-family: serif;
    text-align: center;
    justify-content: center;
    z-index: 2;
    margin-bottom: 40px;
    @media (max-width: 769px) {
        font-size: 18px;
        margin-top: 25px;
        text-align: center;
    }
`;

const Intro = styled.div`
    font-size: 20px;
    text-align: center;
    width: 60%;
    line-height: 1.5;
    margin-bottom: 30px;
    @media (max-width: 768px) {
        font-size: 16px;
        width: 80%;
    }
`;

const Para = styled.div`
    font-size: 20px;
    text-align: left;
    width: 100%;
    line-height: 1.5;
    @media (max-width: 768px) {
        font-size: 16px;
    }
`;



export {
    Blocks,
    Block,
    Details,
    Title,
    Para,
    Intro,
    Subtitle
}